<template>
  <h1>Görevlerim</h1>
</template>

<script>
export default {
  name: 'Tasks',
};
</script>

<style></style>
